"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const action_1 = require("@src/app/services/action");
const imgCache = {};
function importAll(r) {
    r.keys().forEach((key) => (imgCache[key] = r(key).default));
}
//@ts-ignore
importAll(require.context('./assets', false, /\.(svg|webp)$/));
class AreacalculatorCtrl {
    constructor($scope, actionService) {
        this.$scope = $scope;
        this.actionService = actionService;
        this.imgCache = imgCache;
        this.kind = JSON.parse(localStorage.getItem(`${this.constructor.name}_options`) || '{"kind": "square"}').kind;
        this.subKind = JSON.parse(localStorage.getItem(`${this.constructor.name}_options`) || '{"kind": "square"}').subKind;
        this.values = {};
        this.hasError = false;
        this.$scope.$watch(() => {
            return [this.kind, this.subKind];
        }, (value) => {
            localStorage.setItem(`${this.constructor.name}_options`, JSON.stringify({
                kind: value[0],
                subKind: value[1],
            }));
        }, true);
    }
    setKind(kind, subKind) {
        this.kind = kind;
        if (this.kind == 'triangle') {
            //@ts-ignore
            this.subKind = ['base&height', 'sss', 'sas', 'asa'].indexOf(subKind) != -1 ? subKind : 'base&height';
        }
        else if (this.kind == 'parallelogram') {
            //@ts-ignore
            this.subKind = ['base&height', 'side&angle_between', 'diagonals&angle_between'].indexOf(subKind) != -1 ? subKind : 'base&height';
        }
        else if (this.kind == 'rhombus') {
            //@ts-ignore
            this.subKind = ['side&height', 'diagonals', 'side&angle'].indexOf(subKind) != -1 ? subKind : 'side&height';
        }
        else if (this.kind == 'kite') {
            //@ts-ignore
            this.subKind = ['diagonals', '2sides&angle'].indexOf(subKind) != -1 ? subKind : 'diagonals';
        }
        else {
            this.subKind = undefined;
        }
        if (this.areaForm)
            this.clear(this.areaForm);
    }
    $onInit() {
    }
    angleToRadians(angle) {
        return angle * (Math.PI / 180);
    }
    radianToDegree(radian) {
        return radian * (180 / Math.PI);
    }
    sin(value) {
        return Math.sin(this.angleToRadians(value));
    }
    cos(value) {
        return Math.cos(this.angleToRadians(value));
    }
    tan(value) {
        return Math.tan(this.angleToRadians(value));
    }
    cot(value) {
        return 1 / this.tan(value);
    }
    submitForm($form) {
        if ($form.$valid && (Object.keys(this.values).length != 0)) {
            const result = {
                values: ng.copy(this.values)
            };
            this.hasError = false;
            try {
                if (this.kind == 'square') {
                    result.area = Math.pow(this.values.a, 2);
                }
                else if (this.kind == 'rectangle') {
                    result.area = this.values.a * this.values.b;
                }
                else if (this.kind == 'triangle') {
                    if (this.subKind == 'base&height') {
                        result.area = this.values.b * this.values.h / 2;
                    }
                    else if (this.subKind == 'sss') {
                        const A = (this.values.a + this.values.b + this.values.c) *
                            (-1 * this.values.a + this.values.b + this.values.c) *
                            (this.values.a - this.values.b + this.values.c) *
                            (this.values.a + this.values.b - this.values.c);
                        if (A >= 0) {
                            result.area = 0.25 * Math.sqrt(A);
                        }
                        else {
                            throw "can't calculate";
                        }
                    }
                    else if (this.subKind == 'sas') {
                        result.area = 0.5 * this.values.a * this.values.b * this.sin(this.values.Y);
                    }
                    else if (this.subKind == 'asa') {
                        result.area = Math.pow(this.values.a, 2) * this.sin(this.values.B) * this.sin(this.values.Y) /
                            (2 * this.sin(this.values.B + this.values.Y));
                    }
                }
                else if (this.kind == 'circle') {
                    result.area = Math.PI * Math.pow(this.values.r, 2);
                }
                else if (this.kind == 'semicircle') {
                    result.area = Math.PI * Math.pow(this.values.r, 2) / 2;
                }
                else if (this.kind == 'sector') {
                    result.area = this.angleToRadians(this.values.A) * Math.pow(this.values.r, 2) / 2;
                }
                else if (this.kind == 'ellipse') {
                    result.area = Math.PI * this.values.a * this.values.b;
                }
                else if (this.kind == 'trapezoid') {
                    result.area = (this.values.a + this.values.b) * this.values.h / 2;
                }
                else if (this.kind == 'parallelogram') {
                    if (this.subKind == 'base&height') {
                        result.area = this.values.b * this.values.h;
                    }
                    else if (this.subKind == 'side&angle_between') {
                        result.area = this.values.a * this.values.b * this.sin(this.values.A);
                    }
                    else if (this.subKind == 'diagonals&angle_between') {
                        result.area = this.values.e * this.values.f * this.sin(this.values.A);
                    }
                }
                else if (this.kind == 'rhombus') {
                    if (this.subKind == 'side&height') {
                        result.area = this.values.a * this.values.h;
                    }
                    else if (this.subKind == 'diagonals') {
                        result.area = this.values.e * this.values.f / 2;
                    }
                    else if (this.subKind == 'side&angle') {
                        result.area = Math.pow(this.values.a, 2) * this.sin(this.values.A);
                    }
                }
                else if (this.kind == 'kite') {
                    if (this.subKind == 'diagonals') {
                        result.area = this.values.e * this.values.f / 2;
                    }
                    else if (this.subKind == '2sides&angle') {
                        result.area = this.values.a * this.values.b * this.sin(this.values.A);
                    }
                }
                else if (this.kind == 'regular_pentagon') {
                    result.area = Math.pow(this.values.a, 2) * Math.sqrt(25 + 10 * Math.sqrt(5)) / 4;
                }
                else if (this.kind == 'regular_hexagon') {
                    result.area = (3 / 2) * Math.sqrt(3) * Math.pow(this.values.a, 2);
                }
                else if (this.kind == 'regular_octagon') {
                    result.area = 2 * (1 + Math.sqrt(2)) * Math.pow(this.values.a, 2);
                }
                else if (this.kind == 'annulus') {
                    result.area = Math.PI * (Math.pow(this.values.R, 2) - Math.pow(this.values.r, 2));
                }
                else if (this.kind == 'irregular_quadrilateral') {
                    result.area = this.values.e * this.values.f * this.sin(this.values.A);
                }
                else if (this.kind == 'regular_polygon') {
                    result.area = this.values.n * Math.pow(this.values.a, 2) * this.cot(180 / this.values.n) / 4;
                }
                if (Object.keys(result).indexOf('area') != -1) {
                    if (isNaN(result.area) || result.area == null) {
                        throw "Area is wrong calculated";
                    }
                    this.result = result;
                }
                else {
                    this.result = undefined;
                }
            }
            catch (e) {
                console.error(e);
                this.result = undefined;
                this.hasError = true;
            }
        }
    }
    clear($form) {
        $form.$setPristine();
        $form.$setUntouched();
        this.hasError = false;
        this.result = undefined;
        this.values = {};
    }
    copy(id) {
        if (this.result) {
            this.actionService.copyFactory(document.getElementById(id), this.result.volume);
        }
    }
}
AreacalculatorCtrl.$inject = ['$scope', 'ActionService',];
const appModule = ng.module('app');
appModule.service('ActionService', action_1.ActionService);
appModule.component('gameAreacalculator', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: AreacalculatorCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('areacalculator/');
    }]);
